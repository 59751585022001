/* eslint react/jsx-key: off */
import { withStyles } from '@material-ui/core/styles';
import memoize from 'lodash/memoize';
import ProductRequestEmbedded from './ProductRequestEmbedded';
import MakeProductBarcodeButton from './MakeProductBarcodeButton';
import RequestButton from './RequestButton';
import ImagePopover from './ImagePopover';
import QtyInBoxField from './QtyInBoxField';

import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    Responsive,
    SimpleList,
    ReferenceInput,
    AutocompleteInput,
    TextField,
    TextInput,
    ReferenceField,
    Pagination,
} from 'react-admin';

const styles = {
  image: { maxHeight: '3rem' }
}

const ProductPagination = props => <Pagination rowsPerPageOptions={[100, 200, 300]} {...props} />

const ProductFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn />
        <ReferenceInput source="appUserId" reference="appusers" label="resources.products.fields.user" filter={{ realm: "PM" }} alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.username}`
                }
            />
        </ReferenceInput>
        { permissions === "Admin" || permissions === "WM" ?
            <ReferenceInput source="teamId" reference="teams" label="resources.products.fields.team" alwaysOn perPage={999}>
                <AutocompleteInput
                    optionText={choice =>
                        `${choice.name}`
                    }
                />
            </ReferenceInput>
            :
            null
        }
    </Filter>
);

const rowClick = memoize(permissions => (id, basePath, record) => {
    return permissions === 'Admin'
        ? Promise.resolve('edit')
        : Promise.resolve('edit');
});

const ProductList = ({ classes, permissions, ...props }) => (
    <List
        {...props}
        filters={<ProductFilter permissions={permissions} />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={100}
        pagination={<ProductPagination />}
    >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.qty }
                />
            }
            medium={
                <Datagrid
                    {...props}
                    rowClick={rowClick(permissions)}
                    expand={<ProductRequestEmbedded permissions={permissions} />}
                >
                    <TextField source="id" />
                    { permissions === "Admin" ||  permissions === "WM" ? 
                    <ReferenceField source="teamId" reference="teams" label="resources.products.fields.team" allowEmpty={true}>
                          <TextField source="name" />
                    </ReferenceField>
                     : "" }
                    <ImagePopover />
                    <TextField source="name" />
                    <TextField source="qty" />
                    <QtyInBoxField />
                    <TextField source="box_weight" />
                    <TextField source="box_size" />
                    <TextField source="shelf" />
                    <TextField source="country" />
                    <TextField source="company" />
                    <ReferenceField source="appUserId" reference="appusers" label="resources.products.fields.user">
                        <TextField source="username" />
                    </ReferenceField>                    
                    <EditButton />
                    { permissions === "PM" ? <RequestButton /> : "" }
                    { permissions === "WM" ? <MakeProductBarcodeButton /> : "" }
                 
                </Datagrid>
            }
        />
    </List>
);

export default withStyles(styles)(ProductList);
